const Video = () => {
  return (
    <div
      className="video-content-bg"
      style={{
        backgroundImage: "url(assets/images/backgrounds/about-2.jpg)",
      }}
    >
      <div className="bg-overlay" />
      <a
        href="https://firebasestorage.googleapis.com/v0/b/arvotek.appspot.com/o/arvotek.mp4?alt=media&token=baa8d7da-22b7-482e-a26a-e8d096a69958&_gl=1*1dz0usm*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Njk2MjY2NS4xNi4xLjE2OTY5NjM1NTEuMTUuMC4w"
        className="video-play-button popup-youtube pointer-large"
      >
        <span />
      </a>
    </div>
  );
};

export default Video;
