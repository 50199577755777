import Footer from "../components/Footer";
import Header from "../components/Header";
import Clients from "../components/Clients";

const Services = () => {
  return (
    <>
      <Header activePage="Services" />
      <main
        className="animsition-overlay"
        data-animsition-overlay="true"
        style={{ opacity: 1 }}
      >
        {/* page-head start */}
        <section
          id="up"
          className="page-head flex-min-height-box dark-bg-2 _mPS2id-t"
        >
          {/* page-head-bg */}
          <div
            className="page-head-bg overlay-loading2 overlayy-3"
            // style={{
            //   backgroundImage: "url(assets/images/backgrounds/services.jpeg)",
            // }}
          />
          {/* flex-min-height-inner start */}
          <div className="flex-min-height-inner">
            {/* flex-container start */}
            <div className="container top-bottom-padding-120 flex-container response-999">
              {/* column start */}
              <div className="six-columns six-offset">
                <div className="content-left-margin-40">
                  <h2 className="large-title-bold">
                    <span
                      className="load-title-fill tr-delay03"
                      data-text="We Offer"
                    >
                      We Offer
                    </span>
                    <br />
                    <span
                      className="load-title-fill tr-delay04"
                      data-text="The Best"
                    >
                      The Best
                    </span>
                    <br />
                    <span
                      className="load-title-fill tr-delay05"
                      data-text="Services"
                    >
                      Services
                    </span>
                  </h2>
                </div>
              </div>
              {/* column end */}
            </div>
            {/* flex-container end */}
          </div>
          {/* flex-min-height-inner end */}
          {/* scroll-btn start */}
          <a href="#down" className="scroll-btn pointer-large _mPS2id-h">
            <div className="scroll-arrow-box">
              <span className="scroll-arrow" />
            </div>
            <div className="scroll-btn-flip-box">
              <span className="scroll-btn-flip" data-text="Scroll">
                Scroll
              </span>
            </div>
          </a>
          {/* scroll-btn end */}
        </section>
        {/* page-head end */}
        {/* section start */}
        <section
          id="down"
          className="dark-bg-1 top-bottom-padding-120 _mPS2id-t"
        >
          {/* container start */}
          <div className="container text-center">
            <h2
              className="large-title title-fill animated title-fill-anim"
              data-animation="title-fill-anim"
              data-text="What We Do"
            >
              What We Do
            </h2>
          </div>
          {/* container end */}
          {/* top-padding-90 start */}
          <div className="top-padding-90">
            {/* container start */}
            <div className="container">
              <div
                className="services-bg"
                style={{
                  backgroundImage:
                    "url(assets/images/services/achievement-brainstorming-branding-1162968.jpg)",
                }}
              />
            </div>
            {/* container end */}
            {/* flex-container start */}
            <div className="container small top-padding-60 flex-container response-999 services-content">
              {/* column start */}
              <div className="four-columns">
                <div
                  data-animation-container
                  className="content-right-margin-20 animated"
                >
                  <h2
                    data-animation-child
                    className="small-title-oswald red-color overlay-anim-box2 animated overlay-anim2"
                    data-animation="overlay-anim2"
                  >
                    Strategy &amp; Branding
                  </h2>
                  <h3 className="title-style text-color-4">
                    <span
                      data-animation-child
                      className="overlay-anim-box2 overlay-light-bg-1 tr-delay01 animated overlay-anim2"
                      data-animation="overlay-anim2"
                    >
                      Raw denim photo
                    </span>
                    <br />
                    <span
                      data-animation-child
                      className="overlay-anim-box2 overlay-light-bg-1 tr-delay02 animated overlay-anim2"
                      data-animation="overlay-anim2"
                    >
                      booth semiotics
                    </span>
                    <br />
                    <span
                      data-animation-child
                      className="overlay-anim-box2 overlay-light-bg-1 tr-delay03 animated overlay-anim2"
                      data-animation="overlay-anim2"
                    >
                      3 wolf moon
                    </span>
                  </h3>
                  <ul className="list-dots">
                    <li>
                      <p
                        data-animation-child
                        className="p-letter-style text-color-4 fade-anim-box tr-delay04 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        Digital Marketing
                      </p>
                    </li>
                    <li>
                      <p
                        data-animation-child
                        className="p-letter-style text-color-4 fade-anim-box tr-delay05 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        Creative Design
                      </p>
                    </li>
                    <li>
                      <p
                        data-animation-child
                        className="p-letter-style text-color-4 fade-anim-box tr-delay06 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        Analytics &amp; Marketing
                      </p>
                    </li>
                    <li>
                      <p
                        data-animation-child
                        className="p-letter-style text-color-4 fade-anim-box tr-delay07 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        Digital Branding
                      </p>
                    </li>
                    <li>
                      <p
                        data-animation-child
                        className="p-letter-style text-color-4 fade-anim-box tr-delay08 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        Reports &amp; Analytics
                      </p>
                    </li>
                    <li>
                      <p
                        data-animation-child
                        className="p-letter-style text-color-4 fade-anim-box tr-delay09 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        Infographics Content
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              {/* column end */}
              {/* column start */}
              <div className="eight-columns">
                <div className="content-left-margin-20">
                  <p
                    className="p-style-large text-color-5 fade-anim-box tr-delay04 animated fade-anim"
                    data-animation="fade-anim"
                  >
                    Shoreditch cliche marfa godard gochujang kinfolk hoodie you
                    probably haven't heard of them echo park DIY tilde
                    PBR&amp;B. Lomo dreamcatcher bushwick gastropub swag.
                    Hammock sriracha paleo, iPhone swag af flexitarian hella
                    pickled neutra pug. Trust fund chicharrones iPhone stumptown
                    chia. Godard 3 wolf moon tacos try-hard, raw denim locavore
                    messenger bag portland. Blue bottle hashtag schlitz etsy
                    knausgaard, aesthetic tacos freegan lomo deep v fashion axe.
                  </p>
                </div>
              </div>
              {/* column end */}
            </div>
            {/* flex-container end */}
          </div>
          {/* top-padding-90 end */}
          {/* top-padding-90 start */}
          <div className="top-padding-90">
            {/* container start */}
            <div className="container">
              <div
                className="services-bg"
                style={{
                  backgroundImage:
                    "url(assets/images/services/apple-books-computers-115655.jpg)",
                }}
              />
            </div>
            {/* container end */}
            {/* flex-container start */}
            <div className="container small top-padding-60 flex-container response-999 services-content">
              {/* column start */}
              <div className="four-columns">
                <div
                  data-animation-container
                  className="content-right-margin-20 animated"
                >
                  <h2
                    data-animation-child
                    className="small-title-oswald red-color overlay-anim-box2 animated overlay-anim2"
                    data-animation="overlay-anim2"
                  >
                    Managment &amp; Marketing
                  </h2>
                  <h3 className="title-style text-color-4">
                    <span
                      data-animation-child
                      className="overlay-anim-box2 overlay-light-bg-1 tr-delay01 animated overlay-anim2"
                      data-animation="overlay-anim2"
                    >
                      Lo-fi mixtape
                    </span>
                    <br />
                    <span
                      data-animation-child
                      className="overlay-anim-box2 overlay-light-bg-1 tr-delay02 animated overlay-anim2"
                      data-animation="overlay-anim2"
                    >
                      fanny pack
                    </span>
                    <br />
                    <span
                      data-animation-child
                      className="overlay-anim-box2 overlay-light-bg-1 tr-delay03 animated overlay-anim2"
                      data-animation="overlay-anim2"
                    >
                      meditation PBR&amp;B
                    </span>
                  </h3>
                  <ul className="list-dots">
                    <li>
                      <p
                        data-animation-child
                        className="p-letter-style text-color-4 fade-anim-box tr-delay04 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        SEO &amp; Marketing
                      </p>
                    </li>
                    <li>
                      <p
                        data-animation-child
                        className="p-letter-style text-color-4 fade-anim-box tr-delay05 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        Digital Products
                      </p>
                    </li>
                    <li>
                      <p
                        data-animation-child
                        className="p-letter-style text-color-4 fade-anim-box tr-delay06 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        Online Marketing
                      </p>
                    </li>
                    <li>
                      <p
                        data-animation-child
                        className="p-letter-style text-color-4 fade-anim-box tr-delay07 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        Product Design
                      </p>
                    </li>
                    <li>
                      <p
                        data-animation-child
                        className="p-letter-style text-color-4 fade-anim-box tr-delay08 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        Strategy &amp; Planing
                      </p>
                    </li>
                    <li>
                      <p
                        data-animation-child
                        className="p-letter-style text-color-4 fade-anim-box tr-delay09 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        Media Promotion
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              {/* column end */}
              {/* column start */}
              <div className="eight-columns">
                <div className="content-left-margin-20">
                  <p
                    className="p-style-large text-color-5 fade-anim-box tr-delay04 animated fade-anim"
                    data-animation="fade-anim"
                  >
                    Pinterest lumbersexual forage chillwave godard pitchfork
                    vexillologist deep v chia truffaut four dollar toast food
                    truck meditation. Hot chicken coloring book cliche YOLO,
                    crucifix kale chips swag hexagon raclette paleo next level
                    austin. Yuccie thundercats irony prism man braid narwhal
                    mustache taxidermy chillwave. Polaroid af ethical green
                    juice, PBR&amp;B kale chips chicharrones mixtape wayfarers
                    air plant. Godard taxidermy deep v, VHS portland poke prism.
                  </p>
                </div>
              </div>
              {/* column end */}
            </div>
            {/* flex-container end */}
          </div>
          {/* top-padding-90 end */}
          {/* top-padding-90 start */}
          <div className="top-padding-90">
            {/* container start */}
            <div className="container">
              <div
                className="services-bg"
                style={{
                  backgroundImage:
                    "url(assets/images/services/american-casual-cellphone-1262971.jpg)",
                }}
              />
            </div>
            {/* container end */}
            {/* flex-container start */}
            <div className="container small top-padding-60 flex-container response-999 services-content">
              {/* column start */}
              <div className="four-columns">
                <div
                  data-animation-container
                  className="content-right-margin-20 animated"
                >
                  <h2
                    data-animation-child
                    className="small-title-oswald red-color overlay-anim-box2 animated overlay-anim2"
                    data-animation="overlay-anim2"
                  >
                    Media Promotion
                  </h2>
                  <h3 className="title-style text-color-4">
                    <span
                      data-animation-child
                      className="overlay-anim-box2 overlay-light-bg-1 tr-delay01 animated overlay-anim2"
                      data-animation="overlay-anim2"
                    >
                      v pug ethical
                    </span>
                    <br />
                    <span
                      data-animation-child
                      className="overlay-anim-box2 overlay-light-bg-1 tr-delay02 animated overlay-anim2"
                      data-animation="overlay-anim2"
                    >
                      XOXO umami godar
                    </span>
                    <br />
                    <span
                      data-animation-child
                      className="overlay-anim-box2 overlay-light-bg-1 tr-delay03 animated overlay-anim2"
                      data-animation="overlay-anim2"
                    >
                      Cold-pressed blue
                    </span>
                  </h3>
                  <ul className="list-dots">
                    <li>
                      <p
                        data-animation-child
                        className="p-letter-style text-color-4 fade-anim-box tr-delay04 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        Content Marketing
                      </p>
                    </li>
                    <li>
                      <p
                        data-animation-child
                        className="p-letter-style text-color-4 fade-anim-box tr-delay05 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        Market Research
                      </p>
                    </li>
                    <li>
                      <p
                        data-animation-child
                        className="p-letter-style text-color-4 fade-anim-box tr-delay06 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        Environment Consulting
                      </p>
                    </li>
                    <li>
                      <p
                        data-animation-child
                        className="p-letter-style text-color-4 fade-anim-box tr-delay07 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        User Interface
                      </p>
                    </li>
                    <li>
                      <p
                        data-animation-child
                        className="p-letter-style text-color-4 fade-anim-box tr-delay08 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        User Experience
                      </p>
                    </li>
                    <li>
                      <p
                        data-animation-child
                        className="p-letter-style text-color-4 fade-anim-box tr-delay09 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        Experts &amp; Analytics
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              {/* column end */}
              {/* column start */}
              <div className="eight-columns">
                <div className="content-left-margin-20">
                  <p
                    className="p-style-large text-color-5 fade-anim-box tr-delay04 animated fade-anim"
                    data-animation="fade-anim"
                  >
                    Pitchfork green juice meh ethical slow-carb poke authentic
                    salvia skateboard helvetica tousled bicycle rights ennui.
                    Hot chicken ugh drinking vinegar, edison bulb dreamcatcher
                    meh craft beer offal tousled gluten-free locavore. Franzen
                    schlitz gochujang organic +1, godard tacos. Whatever
                    heirloom lyft drinking vinegar. Lyft synth letterpress
                    kinfolk aesthetic shaman banjo hell of. Single-origin coffee
                    aesthetic cray try-hard, williamsburg vegan air plante.
                  </p>
                </div>
              </div>
              {/* column end */}
            </div>
            {/* flex-container end */}
          </div>
          {/* top-padding-90 end */}
        </section>
        {/* section end */}
        {/* progress-container start */}
        <section className="dark-bg-1 flex-min-height-box progress-container">
          {/* flex-min-height-inner start */}
          <div className="flex-min-height-inner">
            {/* flex-container start */}
            <div className="flex-container container top-bottom-padding-120">
              {/* column start */}
              <div className="six-columns bottom-padding-60">
                <div
                  className="progress-bg overlay-anim-box2 animated overlay-anim2"
                  data-animation="overlay-anim2"
                  style={{
                    backgroundImage:
                      "url(assets/images/services/action-asia-blur-1455989.jpg)",
                  }}
                />
              </div>
              {/* column end */}
              {/* column start */}
              <div className="six-columns">
                <div className="content-left-margin-40">
                  {/* medium-title start */}
                  <h2
                    data-animation-container
                    className="medium-title text-center animated"
                  >
                    <span
                      data-animation-child
                      className="title-fill animated title-fill-anim"
                      data-animation="title-fill-anim"
                      data-text="Our skills"
                    >
                      Our skills
                    </span>
                  </h2>
                  {/* medium-title end */}
                  <div
                    className="top-margin-30 animated"
                    data-animation-container
                  >
                    {/* progress-box start */}
                    <div className="progress-box">
                      <div
                        data-animation-child
                        className="p-letter-style title-fill animated title-fill-anim"
                        data-animation="title-fill-anim"
                        data-text="Graphic Design"
                      >
                        Graphic Design
                      </div>
                      <div
                        data-animation-child
                        className="p-letter-style title-fill tr-delay02 progress-counter animated title-fill-anim"
                        data-animation="title-fill-anim"
                        data-text="63%"
                      >
                        63%
                      </div>
                      <div className="progress-zero" data-progress="63%">
                        <div
                          className="progress-full animated slide-progress"
                          data-animation-child
                          data-animation="slide-progress"
                          style={{ width: "63%" }}
                        />
                        <div
                          className="progress-full progress-full-red animated slide-progress"
                          data-animation-child
                          data-animation="slide-progress"
                          data-animation-delay="200ms"
                          style={{ animationDelay: "200ms", width: "63%" }}
                        />
                      </div>
                    </div>
                    {/* progress-box end */}
                    {/* progress-box start */}
                    <div className="progress-box">
                      <div
                        data-animation-child
                        className="p-letter-style title-fill tr-delay01 animated title-fill-anim"
                        data-animation="title-fill-anim"
                        data-text="Product Branding"
                      >
                        Product Branding
                      </div>
                      <div
                        data-animation-child
                        className="p-letter-style title-fill tr-delay03 progress-counter animated title-fill-anim"
                        data-animation="title-fill-anim"
                        data-text="80%"
                      >
                        80%
                      </div>
                      <div className="progress-zero" data-progress="80%">
                        <div
                          className="progress-full animated slide-progress"
                          data-animation-child
                          data-animation="slide-progress"
                          data-animation-delay="100ms"
                          style={{ animationDelay: "100ms", width: "80%" }}
                        />
                        <div
                          className="progress-full progress-full-red animated slide-progress"
                          data-animation-child
                          data-animation="slide-progress"
                          data-animation-delay="300ms"
                          style={{ animationDelay: "300ms", width: "80%" }}
                        />
                      </div>
                    </div>
                    {/* progress-box end */}
                    {/* progress-box start */}
                    <div className="progress-box">
                      <div
                        data-animation-child
                        className="p-letter-style title-fill tr-delay02 animated title-fill-anim"
                        data-animation="title-fill-anim"
                        data-text="Business Planing"
                      >
                        Business Planing
                      </div>
                      <div
                        data-animation-child
                        className="p-letter-style title-fill tr-delay04 progress-counter animated title-fill-anim"
                        data-animation="title-fill-anim"
                        data-text="100%"
                      >
                        100%
                      </div>
                      <div className="progress-zero" data-progress="100%">
                        <div
                          className="progress-full animated slide-progress"
                          data-animation-child
                          data-animation="slide-progress"
                          data-animation-delay="200ms"
                          style={{ animationDelay: "200ms", width: "100%" }}
                        />
                        <div
                          className="progress-full progress-full-red animated slide-progress"
                          data-animation-child
                          data-animation="slide-progress"
                          data-animation-delay="400ms"
                          style={{ animationDelay: "400ms", width: "100%" }}
                        />
                      </div>
                    </div>
                    {/* progress-box end */}
                    {/* progress-box start */}
                    <div className="progress-box">
                      <div
                        data-animation-child
                        className="p-letter-style title-fill tr-delay03 animated title-fill-anim"
                        data-animation="title-fill-anim"
                        data-text="Photography"
                      >
                        Photography
                      </div>
                      <div
                        data-animation-child
                        className="p-letter-style title-fill tr-delay05 progress-counter animated title-fill-anim"
                        data-animation="title-fill-anim"
                        data-text="93%"
                      >
                        93%
                      </div>
                      <div className="progress-zero" data-progress="93%">
                        <div
                          className="progress-full animated slide-progress"
                          data-animation-child
                          data-animation="slide-progress"
                          data-animation-delay="300ms"
                          style={{ animationDelay: "300ms", width: "93%" }}
                        />
                        <div
                          className="progress-full progress-full-red animated slide-progress"
                          data-animation-child
                          data-animation="slide-progress"
                          data-animation-delay="500ms"
                          style={{ animationDelay: "500ms", width: "93%" }}
                        />
                      </div>
                    </div>
                    {/* progress-box end */}
                    {/* progress-box start */}
                    <div className="progress-box">
                      <div
                        data-animation-child
                        className="p-letter-style title-fill tr-delay04 animated title-fill-anim"
                        data-animation="title-fill-anim"
                        data-text="Market Research"
                      >
                        Market Research
                      </div>
                      <div
                        data-animation-child
                        className="p-letter-style title-fill tr-delay06 progress-counter animated title-fill-anim"
                        data-animation="title-fill-anim"
                        data-text="75%"
                      >
                        75%
                      </div>
                      <div className="progress-zero" data-progress="75%">
                        <div
                          className="progress-full animated slide-progress"
                          data-animation-child
                          data-animation="slide-progress"
                          data-animation-delay="400ms"
                          style={{ animationDelay: "400ms", width: "75%" }}
                        />
                        <div
                          className="progress-full progress-full-red animated slide-progress"
                          data-animation-child
                          data-animation="slide-progress"
                          data-animation-delay="600ms"
                          style={{ animationDelay: "600ms", width: "75%" }}
                        />
                      </div>
                    </div>
                    {/* progress-box end */}
                    {/* progress-box start */}
                    <div className="progress-box">
                      <div
                        data-animation-child
                        className="p-letter-style title-fill tr-delay05 animated title-fill-anim"
                        data-animation="title-fill-anim"
                        data-text="Strategic Consulting"
                      >
                        Strategic Consulting
                      </div>
                      <div
                        data-animation-child
                        className="p-letter-style title-fill tr-delay07 progress-counter animated title-fill-anim"
                        data-animation="title-fill-anim"
                        data-text="87%"
                      >
                        87%
                      </div>
                      <div className="progress-zero" data-progress="87%">
                        <div
                          className="progress-full animated slide-progress"
                          data-animation-child
                          data-animation="slide-progress"
                          data-animation-delay="500ms"
                          style={{ animationDelay: "500ms", width: "87%" }}
                        />
                        <div
                          className="progress-full progress-full-red animated slide-progress"
                          data-animation-child
                          data-animation="slide-progress"
                          data-animation-delay="700ms"
                          style={{ animationDelay: "700ms", width: "87%" }}
                        />
                      </div>
                    </div>
                    {/* progress-box end */}
                  </div>
                </div>
              </div>
              {/* column end */}
            </div>
            {/* flex-container end */}
          </div>
          {/* flex-min-height-inner end */}
        </section>
        {/* progress-container end */}

        <Clients />
      </main>

      <Footer activePage="Services" />
    </>
  );
};

export default Services;
