import Header from "../components/Header";
import Footer from "../components/Footer";

const Portfolio = () => {
  return (
    <>
      <Header activePage="Portfolio" />
      <main
        className="animsition-overlay"
        data-animsition-overlay="true"
        style={{ opacity: 1 }}
      >
        {/* page-head start */}
        <section
          id="up"
          className="page-head flex-min-height-box dark-bg-1 _mPS2id-t"
        >
          {/* page-head-bg */}
          <div
            className="page-head-bg overlay-loading2 overlayy-2"
            // style={{
            //   backgroundImage:
            //     "url(assets/images/backgrounds/art-artistic-artsy-1988681.jpg)",
            // }}
          />
          {/* flex-min-height-inner start */}
          <div className="flex-min-height-inner">
            {/* flex-container start */}
            <div className="container top-bottom-padding-120 flex-container response-999">
              {/* column start */}
              <div className="six-columns six-offset">
                <div className="content-left-margin-40">
                  <h2 className="large-title-bold">
                    <span
                      className="load-title-fill tr-delay03"
                      data-text="new approach"
                    >
                      new approach
                    </span>
                    <br />
                    <span
                      className="load-title-fill tr-delay04"
                      data-text="to design"
                    >
                      to design
                    </span>
                    <br />
                    <span
                      className="load-title-fill tr-delay05"
                      data-text="& marketing"
                    >
                      &amp; marketing
                    </span>
                  </h2>
                  <p className="p-style-bold-up text-height-20 d-flex-wrap">
                    <span
                      className="load-title-fill tr-delay08"
                      data-text="XOXO fam brunch"
                    >
                      XOXO fam brunch
                    </span>
                    <span
                      className="load-title-fill tr-delay09"
                      data-text="retro intelligentsia"
                    >
                      retro intelligentsia
                    </span>
                    <span
                      className="load-title-fill tr-delay10"
                      data-text="live-edge vegan"
                    >
                      live-edge vegan
                    </span>
                  </p>
                </div>
              </div>
              {/* column end */}
            </div>
            {/* flex-container end */}
          </div>
          {/* flex-min-height-inner end */}
          {/* scroll-btn start */}
          <a href="#down" className="scroll-btn pointer-large _mPS2id-h">
            <div className="scroll-arrow-box">
              <span className="scroll-arrow" />
            </div>
            <div className="scroll-btn-flip-box">
              <span className="scroll-btn-flip" data-text="Scroll">
                Scroll
              </span>
            </div>
          </a>
          {/* scroll-btn end */}
        </section>
        {/* page-head end */}
        {/* light-bg-1 start */}
        <div
          id="down"
          className="light-bg-1 bottom-padding-30 top-padding-120 _mPS2id-t"
          data-midnight="black"
        >
          {/* container start */}
          <div
            data-animation-container
            className="container small bottom-padding-60 text-center animated"
          >
            <h2
              data-animation-child
              className="large-title text-height-10 text-color-1 overlay-anim-box2 animated overlay-anim2"
              data-animation="overlay-anim2"
            >
              Recent Works
            </h2>
            <br />
            <p
              data-animation-child
              className="fade-anim-box tr-delay02 text-color-1 xsmall-title-oswald top-margin-5 animated fade-anim"
              data-animation="fade-anim"
            >
              We Offer Digital Solutions
            </p>
          </div>
          {/* container end */}
          {/* bottom-padding-90 start */}
          <section className="bottom-padding-90">
            {/* portfolio-content start */}
            <div className="portfolio-content flex-min-height-box">
              {/* portfolio-content-inner start */}
              <div className="portfolio-content-inner flex-min-height-inner">
                {/* flex-container start */}
                <div className="flex-container container small">
                  {/* column start */}
                  <div
                    data-animation-container
                    className="six-columns animated"
                  >
                    <div className="content-right-margin-40">
                      <span
                        className="small-title-oswald red-color overlay-anim-box2 animated overlay-anim2"
                        data-animation="overlay-anim2"
                      >
                        Commercial
                      </span>
                      <h3 className="title-style text-color-1">
                        <span
                          data-animation-child
                          className="overlay-anim-box2 overlay-dark-bg-2 tr-delay01 animated overlay-anim2"
                          data-animation="overlay-anim2"
                        >
                          Bushwick selfies
                        </span>
                        <br />
                        <span
                          data-animation-child
                          className="overlay-anim-box2 overlay-dark-bg-2 tr-delay02 animated overlay-anim2"
                          data-animation="overlay-anim2"
                        >
                          pork belly lyft
                        </span>
                        <br />
                        <span
                          data-animation-child
                          className="overlay-anim-box2 overlay-dark-bg-2 tr-delay03 animated overlay-anim2"
                          data-animation="overlay-anim2"
                        >
                          brooklyn messeng
                        </span>
                      </h3>
                      <p
                        data-animation-child
                        className="p-style-small text-color-2 fade-anim-box tr-delay04 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        Narwhal pop-up intelligentsia tbh pinterest, microdosing
                        tilde cloud bread gochujang tattooed leggings cornhole
                        8-bit. Austin fam chia cold-pressed raw denim. Glossier
                        drinking vinegar portland lo-fi, polaroid bespoke lomo.
                        Banjo art party XOXO, fashion axe sustainable retro
                        ethical gentrify.
                      </p>
                      <div
                        data-animation-child
                        className="arrow-btn-box top-margin-30 fade-anim-box tr-delay05 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        <a
                          href="project.html"
                          className="arrow-btn pointer-large animsition-link"
                        >
                          Read more
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* column end */}
                  {/* column start */}
                  <div className="six-columns top-padding-60">
                    <a
                      href="project.html"
                      className="portfolio-content-bg-box pointer-large hover-box hidden-box animsition-link"
                    >
                      <div
                        className="portfolio-content-bg hover-img overlay-anim-box2 overlay-dark-bg-2 animated overlay-anim2"
                        data-animation="overlay-anim2"
                        style={{
                          backgroundImage:
                            "url(assets/images/projects/pexels-photo-1619654.jpg)",
                        }}
                      />
                    </a>
                  </div>
                  {/* column end */}
                </div>
                {/* flex-container end */}
              </div>
              {/* portfolio-content-inner end */}
            </div>
            {/* portfolio-content end */}
          </section>
          {/* bottom-padding-90 end */}
          {/* bottom-padding-90 start */}
          <section className="bottom-padding-90">
            {/* portfolio-content start */}
            <div className="portfolio-content flex-min-height-box">
              {/* portfolio-content-inner start */}
              <div className="portfolio-content-inner flex-min-height-inner">
                {/* flex-container start */}
                <div className="flex-container reverse container small">
                  {/* column start */}
                  <div className="six-columns top-padding-60">
                    <a
                      href="project.html"
                      className="portfolio-content-bg-box pointer-large hover-box hidden-box animsition-link"
                    >
                      <div
                        className="portfolio-content-bg hover-img overlay-anim-box2 overlay-dark-bg-2 animated overlay-anim2"
                        data-animation="overlay-anim2"
                        style={{
                          backgroundImage:
                            "url(assets/images/projects/audio-cassette-cassette-tape-1626481.jpg)",
                        }}
                      />
                    </a>
                  </div>
                  {/* column end */}
                  {/* column start */}
                  <div
                    data-animation-container
                    className="six-columns animated"
                  >
                    <div className="content-left-margin-40">
                      <span
                        className="small-title-oswald red-color overlay-anim-box2 animated overlay-anim2"
                        data-animation="overlay-anim2"
                      >
                        Web design
                      </span>
                      <h3 className="title-style text-color-1">
                        <span
                          data-animation-child
                          className="overlay-anim-box2 overlay-dark-bg-2 tr-delay01 animated overlay-anim2"
                          data-animation="overlay-anim2"
                        >
                          tumeric tumblr
                        </span>
                        <br />
                        <span
                          data-animation-child
                          className="overlay-anim-box2 overlay-dark-bg-2 tr-delay02 animated overlay-anim2"
                          data-animation="overlay-anim2"
                        >
                          gluten-free
                        </span>
                        <br />
                        <span
                          data-animation-child
                          className="overlay-anim-box2 overlay-dark-bg-2 tr-delay03 animated overlay-anim2"
                          data-animation="overlay-anim2"
                        >
                          Man bun small
                        </span>
                      </h3>
                      <p
                        data-animation-child
                        className="p-style-small text-color-2 fade-anim-box tr-delay04 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        Slow-carb green juice subway tile bicycle rights, fanny
                        pack raclette palo santo put a bird on it mustache
                        actually fam mumblecore iPhone. Iceland post-ironic
                        health goth snackwave, mixtape synth four dollar toast
                        sartorial. Health goth la croix vexillologist, before
                        they sold out shabby chic.
                      </p>
                      <div
                        data-animation-child
                        className="arrow-btn-box top-margin-30 fade-anim-box tr-delay05 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        <a
                          href="project.html"
                          className="arrow-btn pointer-large animsition-link"
                        >
                          Read more
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* column end */}
                </div>
                {/* flex-container end */}
              </div>
              {/* portfolio-content-inner end */}
            </div>
            {/* portfolio-content end */}
          </section>
          {/* bottom-padding-90 end */}
          {/* bottom-padding-90 start */}
          <section className="bottom-padding-90">
            {/* portfolio-content start */}
            <div className="portfolio-content flex-min-height-box">
              {/* portfolio-content-inner start */}
              <div className="portfolio-content-inner flex-min-height-inner">
                {/* flex-container start */}
                <div className="flex-container container small">
                  {/* column start */}
                  <div
                    data-animation-container
                    className="six-columns animated"
                  >
                    <div className="content-right-margin-40">
                      <span
                        className="small-title-oswald red-color overlay-anim-box2 animated overlay-anim2"
                        data-animation="overlay-anim2"
                      >
                        Graphic Design
                      </span>
                      <h3 className="title-style text-color-1">
                        <span
                          data-animation-child
                          className="overlay-anim-box2 overlay-dark-bg-2 tr-delay01 animated overlay-anim2"
                          data-animation="overlay-anim2"
                        >
                          batch kombucha
                        </span>
                        <br />
                        <span
                          data-animation-child
                          className="overlay-anim-box2 overlay-dark-bg-2 tr-delay02 animated overlay-anim2"
                          data-animation="overlay-anim2"
                        >
                          subway tile
                        </span>
                        <br />
                        <span
                          data-animation-child
                          className="overlay-anim-box2 overlay-dark-bg-2 tr-delay03 animated overlay-anim2"
                          data-animation="overlay-anim2"
                        >
                          salvia brooklyn
                        </span>
                      </h3>
                      <p
                        data-animation-child
                        className="p-style-small text-color-2 fade-anim-box tr-delay04 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        Mlkshk YOLO wolf, leggings vinyl crucifix stumptown
                        tousled. Pabst venmo gentrify deep v microdosing migas
                        occupy master cleanse intelligentsia sartorial chia
                        activated charcoal. Iceland small batch live-edge
                        raclette roof party dreamcatcher austin pickled.
                        Chillwave cronut messenger bag truffaut.
                      </p>
                      <div
                        data-animation-child
                        className="arrow-btn-box top-margin-30 fade-anim-box tr-delay05 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        <a
                          href="project.html"
                          className="arrow-btn pointer-large animsition-link"
                        >
                          Read more
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* column end */}
                  {/* column start */}
                  <div className="six-columns top-padding-60">
                    <a
                      href="project.html"
                      className="portfolio-content-bg-box pointer-large hover-box hidden-box animsition-link"
                    >
                      <div
                        className="portfolio-content-bg hover-img overlay-anim-box2 overlay-dark-bg-2 animated overlay-anim2"
                        data-animation="overlay-anim2"
                        style={{
                          backgroundImage:
                            "url(assets/images/projects/blur-close-up-equipment-1034651.jpg)",
                        }}
                      />
                    </a>
                  </div>
                  {/* column end */}
                </div>
                {/* flex-container end */}
              </div>
              {/* portfolio-content-inner end */}
            </div>
            {/* portfolio-content end */}
          </section>
          {/* bottom-padding-90 end */}
          {/* bottom-padding-90 start */}
          <section className="bottom-padding-90">
            {/* portfolio-content start */}
            <div className="portfolio-content flex-min-height-box">
              {/* portfolio-content-inner start */}
              <div className="portfolio-content-inner flex-min-height-inner">
                {/* flex-container start */}
                <div className="flex-container reverse container small">
                  {/* column start */}
                  <div className="six-columns top-padding-60">
                    <a
                      href="project.html"
                      className="portfolio-content-bg-box pointer-large hover-box hidden-box animsition-link"
                    >
                      <div
                        className="portfolio-content-bg hover-img overlay-anim-box2 overlay-dark-bg-2 animated overlay-anim2"
                        data-animation="overlay-anim2"
                        style={{
                          backgroundImage:
                            "url(assets/images/projects/chocolate-delicious-dessert-890500.jpg)",
                        }}
                      />
                    </a>
                  </div>
                  {/* column end */}
                  {/* column start */}
                  <div
                    data-animation-container
                    className="six-columns animated"
                  >
                    <div className="content-left-margin-40">
                      <span
                        className="small-title-oswald red-color overlay-anim-box2 animated overlay-anim2"
                        data-animation="overlay-anim2"
                      >
                        Commercial
                      </span>
                      <h3 className="title-style text-color-1">
                        <span
                          data-animation-child
                          className="overlay-anim-box2 overlay-dark-bg-2 tr-delay01 animated overlay-anim2"
                          data-animation="overlay-anim2"
                        >
                          organic activated
                        </span>
                        <br />
                        <span
                          data-animation-child
                          className="overlay-anim-box2 overlay-dark-bg-2 tr-delay02 animated overlay-anim2"
                          data-animation="overlay-anim2"
                        >
                          charcoal vape
                        </span>
                        <br />
                        <span
                          data-animation-child
                          className="overlay-anim-box2 overlay-dark-bg-2 tr-delay03 animated overlay-anim2"
                          data-animation="overlay-anim2"
                        >
                          viral ennui
                        </span>
                      </h3>
                      <p
                        data-animation-child
                        className="p-style-small text-color-2 fade-anim-box tr-delay04 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        Tote bag cornhole pork belly swag, cronut hoodie
                        snackwave 90's messenger bag pour-over disrupt
                        chartreuse. Vape ugh cardigan hell of. Vaporware umami
                        master cleanse neutra, chartreuse flexitarian lo-fi
                        selvage hella hoodie freegan gentrify. 8-bit air plant
                        umami asymmetrical franzen semiotics before.
                      </p>
                      <div
                        data-animation-child
                        className="arrow-btn-box top-margin-30 fade-anim-box tr-delay05 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        <a
                          href="project.html"
                          className="arrow-btn pointer-large animsition-link"
                        >
                          Read more
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* column end */}
                </div>
                {/* flex-container end */}
              </div>
              {/* portfolio-content-inner end */}
            </div>
            {/* portfolio-content end */}
          </section>
          {/* bottom-padding-90 end */}
          {/* bottom-padding-90 start */}
          <section className="bottom-padding-90">
            {/* portfolio-content start */}
            <div className="portfolio-content flex-min-height-box">
              {/* portfolio-content-inner start */}
              <div className="portfolio-content-inner flex-min-height-inner">
                {/* flex-container start */}
                <div className="flex-container container small">
                  {/* column start */}
                  <div
                    data-animation-container
                    className="six-columns animated"
                  >
                    <div className="content-right-margin-40">
                      <span
                        className="small-title-oswald red-color overlay-anim-box2 animated overlay-anim2"
                        data-animation="overlay-anim2"
                      >
                        Branding
                      </span>
                      <h3 className="title-style text-color-1">
                        <span
                          data-animation-child
                          className="overlay-anim-box2 overlay-dark-bg-2 tr-delay01 animated overlay-anim2"
                          data-animation="overlay-anim2"
                        >
                          Bushwick selfies
                        </span>
                        <br />
                        <span
                          data-animation-child
                          className="overlay-anim-box2 overlay-dark-bg-2 tr-delay02 animated overlay-anim2"
                          data-animation="overlay-anim2"
                        >
                          pork belly lyft
                        </span>
                        <br />
                        <span
                          data-animation-child
                          className="overlay-anim-box2 overlay-dark-bg-2 tr-delay03 animated overlay-anim2"
                          data-animation="overlay-anim2"
                        >
                          brooklyn messeng
                        </span>
                      </h3>
                      <p
                        data-animation-child
                        className="p-style-small text-color-2 fade-anim-box tr-delay04 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        Narwhal pop-up intelligentsia tbh pinterest, microdosing
                        tilde cloud bread gochujang tattooed leggings cornhole
                        8-bit. Austin fam chia cold-pressed raw denim. Glossier
                        drinking vinegar portland lo-fi, polaroid bespoke lomo.
                        Banjo art party XOXO, fashion axe sustainable retro
                        ethical gentrify.
                      </p>
                      <div
                        data-animation-child
                        className="arrow-btn-box top-margin-30 fade-anim-box tr-delay05 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        <a
                          href="project.html"
                          className="arrow-btn pointer-large animsition-link"
                        >
                          Read more
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* column end */}
                  {/* column start */}
                  <div className="six-columns top-padding-60">
                    <a
                      href="project.html"
                      className="portfolio-content-bg-box pointer-large hover-box hidden-box animsition-link"
                    >
                      <div
                        className="portfolio-content-bg hover-img overlay-anim-box2 overlay-dark-bg-2 animated overlay-anim2"
                        data-animation="overlay-anim2"
                        style={{
                          backgroundImage:
                            "url(assets/images/projects/adult-bulb-close-up-973506.jpg)",
                        }}
                      />
                    </a>
                  </div>
                  {/* column end */}
                </div>
                {/* flex-container end */}
              </div>
              {/* portfolio-content-inner end */}
            </div>
            {/* portfolio-content end */}
          </section>
          {/* bottom-padding-90 end */}
          {/* bottom-padding-90 start */}
          <section className="bottom-padding-90">
            {/* portfolio-content start */}
            <div className="portfolio-content flex-min-height-box">
              {/* portfolio-content-inner start */}
              <div className="portfolio-content-inner flex-min-height-inner">
                {/* flex-container start */}
                <div className="flex-container reverse container small">
                  {/* column start */}
                  <div className="six-columns top-padding-60">
                    <a
                      href="project.html"
                      className="portfolio-content-bg-box pointer-large hover-box hidden-box animsition-link"
                    >
                      <div
                        className="portfolio-content-bg hover-img overlay-anim-box2 overlay-dark-bg-2 animated overlay-anim2"
                        data-animation="overlay-anim2"
                        style={{
                          backgroundImage:
                            "url(assets/images/projects/close-up-computer-control-1579240.jpg)",
                        }}
                      />
                    </a>
                  </div>
                  {/* column end */}
                  {/* column start */}
                  <div
                    data-animation-container
                    className="six-columns animated"
                  >
                    <div className="content-left-margin-40">
                      <span
                        className="small-title-oswald red-color overlay-anim-box2 animated overlay-anim2"
                        data-animation="overlay-anim2"
                      >
                        Web design
                      </span>
                      <h3 className="title-style text-color-1">
                        <span
                          data-animation-child
                          className="overlay-anim-box2 overlay-dark-bg-2 tr-delay01 animated overlay-anim2"
                          data-animation="overlay-anim2"
                        >
                          tumeric tumblr
                        </span>
                        <br />
                        <span
                          data-animation-child
                          className="overlay-anim-box2 overlay-dark-bg-2 tr-delay02 animated overlay-anim2"
                          data-animation="overlay-anim2"
                        >
                          gluten-free
                        </span>
                        <br />
                        <span
                          data-animation-child
                          className="overlay-anim-box2 overlay-dark-bg-2 tr-delay03 animated overlay-anim2"
                          data-animation="overlay-anim2"
                        >
                          Man bun small
                        </span>
                      </h3>
                      <p
                        data-animation-child
                        className="p-style-small text-color-2 fade-anim-box tr-delay04 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        Slow-carb green juice subway tile bicycle rights, fanny
                        pack raclette palo santo put a bird on it mustache
                        actually fam mumblecore iPhone. Iceland post-ironic
                        health goth snackwave, mixtape synth four dollar toast
                        sartorial. Health goth la croix vexillologist, before
                        they sold out shabby chic.
                      </p>
                      <div
                        data-animation-child
                        className="arrow-btn-box top-margin-30 fade-anim-box tr-delay05 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        <a
                          href="project.html"
                          className="arrow-btn pointer-large animsition-link"
                        >
                          Read more
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* column end */}
                </div>
                {/* flex-container end */}
              </div>
              {/* portfolio-content-inner end */}
            </div>
            {/* portfolio-content end */}
          </section>
          {/* bottom-padding-90 end */}
          {/* bottom-padding-90 start */}
          <section className="bottom-padding-90">
            {/* portfolio-content start */}
            <div className="portfolio-content flex-min-height-box">
              {/* portfolio-content-inner start */}
              <div className="portfolio-content-inner flex-min-height-inner">
                {/* flex-container start */}
                <div className="flex-container container small">
                  {/* column start */}
                  <div
                    data-animation-container
                    className="six-columns animated"
                  >
                    <div className="content-right-margin-40">
                      <span
                        className="small-title-oswald red-color overlay-anim-box2 animated overlay-anim2"
                        data-animation="overlay-anim2"
                      >
                        Graphic Design
                      </span>
                      <h3 className="title-style text-color-1">
                        <span
                          data-animation-child
                          className="overlay-anim-box2 overlay-dark-bg-2 tr-delay01 animated overlay-anim2"
                          data-animation="overlay-anim2"
                        >
                          batch kombucha
                        </span>
                        <br />
                        <span
                          data-animation-child
                          className="overlay-anim-box2 overlay-dark-bg-2 tr-delay02 animated overlay-anim2"
                          data-animation="overlay-anim2"
                        >
                          subway tile
                        </span>
                        <br />
                        <span
                          data-animation-child
                          className="overlay-anim-box2 overlay-dark-bg-2 tr-delay03 animated overlay-anim2"
                          data-animation="overlay-anim2"
                        >
                          salvia brooklyn
                        </span>
                      </h3>
                      <p
                        data-animation-child
                        className="p-style-small text-color-2 fade-anim-box tr-delay04 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        Mlkshk YOLO wolf, leggings vinyl crucifix stumptown
                        tousled. Pabst venmo gentrify deep v microdosing migas
                        occupy master cleanse intelligentsia sartorial chia
                        activated charcoal. Iceland small batch live-edge
                        raclette roof party dreamcatcher austin pickled.
                        Chillwave cronut messenger bag truffaut.
                      </p>
                      <div
                        data-animation-child
                        className="arrow-btn-box top-margin-30 fade-anim-box tr-delay05 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        <a
                          href="project.html"
                          className="arrow-btn pointer-large animsition-link"
                        >
                          Read more
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* column end */}
                  {/* column start */}
                  <div className="six-columns top-padding-60">
                    <a
                      href="project.html"
                      className="portfolio-content-bg-box pointer-large hover-box hidden-box animsition-link"
                    >
                      <div
                        className="portfolio-content-bg hover-img overlay-anim-box2 overlay-dark-bg-2 animated overlay-anim2"
                        data-animation="overlay-anim2"
                        style={{
                          backgroundImage:
                            "url(assets/images/projects/blades-footwear-hanging-914996.jpg)",
                        }}
                      />
                    </a>
                  </div>
                  {/* column end */}
                </div>
                {/* flex-container end */}
              </div>
              {/* portfolio-content-inner end */}
            </div>
            {/* portfolio-content end */}
          </section>
          {/* bottom-padding-90 end */}
          {/* bottom-padding-90 start */}
          <section className="bottom-padding-90">
            {/* portfolio-content start */}
            <div className="portfolio-content flex-min-height-box">
              {/* portfolio-content-inner start */}
              <div className="portfolio-content-inner flex-min-height-inner">
                {/* flex-container start */}
                <div className="flex-container reverse container small">
                  {/* column start */}
                  <div className="six-columns top-padding-60">
                    <a
                      href="project.html"
                      className="portfolio-content-bg-box pointer-large hover-box hidden-box animsition-link"
                    >
                      <div
                        className="portfolio-content-bg hover-img overlay-anim-box2 overlay-dark-bg-2 animated overlay-anim2"
                        data-animation="overlay-anim2"
                        style={{
                          backgroundImage:
                            "url(assets/images/projects/buttons-console-dark-1637438.jpg)",
                        }}
                      />
                    </a>
                  </div>
                  {/* column end */}
                  {/* column start */}
                  <div
                    data-animation-container
                    className="six-columns animated"
                  >
                    <div className="content-left-margin-40">
                      <span
                        className="small-title-oswald red-color overlay-anim-box2 animated overlay-anim2"
                        data-animation="overlay-anim2"
                      >
                        Branding
                      </span>
                      <h3 className="title-style text-color-1">
                        <span
                          data-animation-child
                          className="overlay-anim-box2 overlay-dark-bg-2 tr-delay01 animated overlay-anim2"
                          data-animation="overlay-anim2"
                        >
                          organic activated
                        </span>
                        <br />
                        <span
                          data-animation-child
                          className="overlay-anim-box2 overlay-dark-bg-2 tr-delay02 animated overlay-anim2"
                          data-animation="overlay-anim2"
                        >
                          charcoal vape
                        </span>
                        <br />
                        <span
                          data-animation-child
                          className="overlay-anim-box2 overlay-dark-bg-2 tr-delay03 animated overlay-anim2"
                          data-animation="overlay-anim2"
                        >
                          viral ennui
                        </span>
                      </h3>
                      <p
                        data-animation-child
                        className="p-style-small text-color-2 fade-anim-box tr-delay04 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        Tote bag cornhole pork belly swag, cronut hoodie
                        snackwave 90's messenger bag pour-over disrupt
                        chartreuse. Vape ugh cardigan hell of. Vaporware umami
                        master cleanse neutra, chartreuse flexitarian lo-fi
                        selvage hella hoodie freegan gentrify. 8-bit air plant
                        umami asymmetrical franzen semiotics before.
                      </p>
                      <div
                        data-animation-child
                        className="arrow-btn-box top-margin-30 fade-anim-box tr-delay05 animated fade-anim"
                        data-animation="fade-anim"
                      >
                        <a
                          href="project.html"
                          className="arrow-btn pointer-large animsition-link"
                        >
                          Read more
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* column end */}
                </div>
                {/* flex-container end */}
              </div>
              {/* portfolio-content-inner end */}
            </div>
            {/* portfolio-content end */}
          </section>
          {/* bottom-padding-90 end */}
        </div>
        {/* light-bg-1 end */}
        {/* <div
          className="text-center top-bottom-padding-120 red-bg"
          data-midnight="black"
        >
          <a href="#/" className="pointer-large overlay-btn-box">
            <span className="overlay-btn" data-text="loading more">
              loading more
            </span>
          </a>
        </div> */}
      </main>

      <Footer activePage="Portfolio" />
    </>
  );
};

export default Portfolio;
